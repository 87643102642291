'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import * as track from '../../modules/analytics/track'

export const TrackPageView = () => {
  return <Tracker />
}

const Tracker = () => {
  if (typeof location === 'undefined' || typeof document === 'undefined') {
    return null
  }
  const pathname = usePathname()
  const searchParams = useSearchParams()
  useEffect(() => {
    track.init()
  }, [])
  useEffect(() => {
    track.view(pathname + location.search, document.title)
  }, [pathname, searchParams, document.title])

  return null
}
